import * as TYPES from './constants';

const initialState = {
  open: false,
  recipients: [],
  callback: () => {},
};

const sendMail = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SEND_MESSAGE_OPEN:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.SEND_MESSAGE_CLOSE:
      return {
        ...state,
        open: false,
        recipients: [],
        callback: () => {},
      };
    default:
      return state;
  }
};

export default sendMail;
